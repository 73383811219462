import jwtDecode from "jwt-decode";



const getAuthorisation = (auth) => {
    let isAdmin = false
    let isSupport = false
    let isDealer = false
    let status = "INVALID"

    if (auth) {
        // console.log("Authorisation from: ", auth);
        const decoded = jwtDecode(auth)
        console.log("GET AUTHORISATION: ", decoded)
        if (decoded?.account.id) {
            const {id: userid, user_name, roles} = decoded.account

            isAdmin = roles.includes("admin")
            let status = isAdmin ? "ADMIN" : "USER"

            return {
                account : decoded.account,
                isAdmin,
                status,
                authorised : true
            }
        }
    }

    return {
        account : null,
        isAdmin,
        status,
        authorised : false
    }
}

export default getAuthorisation