import BaseApi from "./baseApi";


class SquirrelApiClass {

    async save(link_detail) {
        if (link_detail.id === null) {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.put, `/squirrel/linking/link`, link_detail, null) ;
        }
        else {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.post, `/squirrel/linking/link?link_id=${link_detail.id}`, link_detail, null) ;
        }
    }

    async updateLink(link_detail) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.post, `/squirrel/linking/editlink?link_id=${link_detail.id}`, link_detail, null) ;
    }

    async cancelLink(id) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.post, `/squirrel/linking/cancellink`, {id : id}, null) ;
    }

    async linkedToday() {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/linking/todays_scan`, null, null) ;
    }

    async getAllUsers(includeDeleted) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/users/get_all?include_deleted=${includeDeleted}`, null, null) ;
    }

    async getUserById(id) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/users/find_by_id?id=${id}`, null, null) ;
    }

    async getUserByLoginName(login) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/users/find_by_login?login=${login}`, null, null) ;
    }

    async saveUser(user) {
        if (!user.id) {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.put, `/squirrel/users/create`, user, null) ;
        }
        else {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.post, `/squirrel/users/update`, user, null) ;
        }
    }

    async updateUserPassword(user) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.post, `/squirrel/users/update/password`, user, null) ;
    }

    async adminUpdateUserPassword(user) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.post, `/squirrel/users/admin/set-password`, user, null) ;
    }

    async searchLinkWithSerialOrSim(serial_number, sim_number) {
        if (serial_number && sim_number) {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/search/serial_numbers?serial_number=${serial_number}&sim_number=${sim_number}`, null, null) ;
        }
        else if (serial_number) {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/search/serial_numbers?serial_number=${serial_number}`, null, null) ;
        }
        else if (sim_number) {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/search/serial_numbers?sim_number=${sim_number}`, null, null) ;
        }
        else {
            return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/search/serial_numbers`, null, null) ;
        }
    }

    async searchScanHistoryDay(target_date) {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/search/history_day?target_date=${target_date}`, null, null)
    }

    async searchScanHistoryCounts() {
        return await BaseApi.apiCallWithRefresh(BaseApi.client.get, `/squirrel/search/history_all`, null, null)
    }
}

const Squirrel = new SquirrelApiClass();
export default Squirrel;