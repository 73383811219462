

/*
NOTE: This is not the ideal way of handling translations and it certainly will be unmanagable for large text volumes.

For this relatively small/simple app it's ok but would need revisiting for a larger app.

 */

export const TranslationKeys = {
    TXT_LOGIN : 1,
    TXT_LOGOUT : 2,
    TXT_MANAGE_USERS : 3,
    TXT_LINK_SERIAL_NUMBERS : 4,
    TXT_SQUIRREL_MANUFACTURING_PORTAL : 5,
    TXT_USERNAME : 6,
    TXT_PASSWORD : 7,
    TXT_CHINESE : 8,
    TXT_ENGLISH : 9,
    TXT_SCAN_NOTE : 10,
    TXT_LINK_HEADING : 11,
    TXT_HOIST_SERIAL_LABEL : 12,
    TXT_SIM_SERIAL_LABEL : 13,
    TXT_SCAN_COUNT_LABEL : 14,
    TXT_RESET_LABEL : 15,
    TXT_LINK_ALREADY_EXISTS : 16,
    TXT_LINK_CREATED : 17,
    TXT_LINK_ERROR : 18,
    TXT_LINK_ALREADY_EXISTS_SERIAL : 19,
    TXT_LINK_ALREADY_EXISTS_SIM : 20,
    TXT_SAVE_LABEL : 21,
    TXT_REFRESH_LABEL : 22,
    TXT_CANCEL_LABEL : 23,
    TXT_LOGIN_NAME : 24,
    TXT_FRIENDLY_NAME : 25,
    TXT_PASSWORD_CONFIRM : 26,
    TXT_NEW_USER : 27,
    TXT_ACTIVE_USER : 28,
    TXT_DELETED_USER : 29,
    TXT_LAST_LOGIN : 30,
    TXT_SHOW_PASSWORD : 31,
    TXT_ADMIN_USER : 32,
    TXT_CHANGE_PASSWORD : 33,
    TXT_UPDATE_MY_DETAILS : 34,
    TXT_CURRENT_PASSWORD : 35,
    TXT_NEW_PASSWORD : 36,
    TXT_PASSWORD_UPDATE_FAILED : 37,
    TXT_PASSWORDS_DONT_MATCH : 38,
    TXT_INVALID_PASSWORD : 39,
    TXT_CREATED : 40,
    TXT_LAST_UPDATED : 41,
    TXT_FULL_NAME : 42,
    TXT_ROLES : 43,
    TXT_SCANNED_TODAY : 44,
    TXT_LABEL_CREATE : 45,
    TXT_LABEL_UPDATE : 46,
    TXT_LABEL_EDIT : 47,
    TXT_LABEL_CHANGE_PASSWORD : 48,
    TXT_LINK_SIM_NUMBER : 49,
    TXT_LABEL_SCANNED_BY : 50,
    TXT_VIEW_SCAN_HISTORY : 51,
    TXT_LABEL_SEARCH : 52,
    TXT_SIM_STATE : 53,
    TXT_SIM_ACTIVATION_TIME : 54,
    TXT_SIM_LAST_STATUS_CHECK : 55,
    TXT_LABEL_DAILY_SCAN_COUNTS : 56,
    TXT_LABEL_SCANNED_ITEMS : 57,
    TXT_LABEL_MISSING_SERIAL : 58,
    TXT_LABEL_MISSING_SIM : 59,
    TXT_LABEL_SERIAL_SIM_SAME : 60,
}

const englishText = (k,d) => {
    switch (k) {
        case TranslationKeys.TXT_LOGIN : return "Login" ;
        case TranslationKeys.TXT_LOGOUT : return "Logout" ;
        case TranslationKeys.TXT_MANAGE_USERS : return "Manage Users" ;
        case TranslationKeys.TXT_LINK_SERIAL_NUMBERS : return "Lift/hoist serial number" ;
        case TranslationKeys.TXT_SQUIRREL_MANUFACTURING_PORTAL : return "Squirrel Manufacturing Portal" ;
        case TranslationKeys.TXT_USERNAME : return "Username" ;
        case TranslationKeys.TXT_PASSWORD : return "Password" ;
        case TranslationKeys.TXT_CHINESE : return "中文" ;
        case TranslationKeys.TXT_ENGLISH : return "English" ;
        case TranslationKeys.TXT_SCAN_NOTE : return "To accept, press the return key or click on the code below. Alternatively scan the code on the screen" ;
        case TranslationKeys.TXT_LINK_HEADING : return "Link Serial Numbers to SIM cards" ;
        case TranslationKeys.TXT_HOIST_SERIAL_LABEL : return "Lift Serial Number" ;
        case TranslationKeys.TXT_SIM_SERIAL_LABEL : return "PCB SIM Serial Number" ;
        case TranslationKeys.TXT_SCAN_COUNT_LABEL : return "Scan Count" ;
        case TranslationKeys.TXT_RESET_LABEL : return "Reset" ;
        case TranslationKeys.TXT_LINK_ALREADY_EXISTS : return "Link already exists" ;
        case TranslationKeys.TXT_LINK_CREATED : return "Link created" ;
        case TranslationKeys.TXT_LINK_ERROR : return "Link error" ;
        case TranslationKeys.TXT_LINK_ALREADY_EXISTS_SERIAL : return "Serial number already linked" ;
        case TranslationKeys.TXT_LINK_ALREADY_EXISTS_SIM : return "SIM number already linked" ;
        case TranslationKeys.TXT_SAVE_LABEL : return "Save" ;
        case TranslationKeys.TXT_REFRESH_LABEL : return "Refresh" ;
        case TranslationKeys.TXT_CANCEL_LABEL : return "Cancel" ;
        case TranslationKeys.TXT_LOGIN_NAME : return "Login Name" ;
        case TranslationKeys.TXT_FRIENDLY_NAME : return "Full Name" ;
        case TranslationKeys.TXT_PASSWORD_CONFIRM : return "Confirm Password" ;
        case TranslationKeys.TXT_NEW_USER : return "New User" ;
        case TranslationKeys.TXT_ACTIVE_USER : return "Active" ;
        case TranslationKeys.TXT_DELETED_USER : return "Deleted" ;
        case TranslationKeys.TXT_LAST_LOGIN : return "Last Login" ;
        case TranslationKeys.TXT_SHOW_PASSWORD : return "Show Password" ;
        case TranslationKeys.TXT_ADMIN_USER : return "Admin User" ;
        case TranslationKeys.TXT_CHANGE_PASSWORD : return "Change My Password" ;
        case TranslationKeys.TXT_UPDATE_MY_DETAILS : return "Update My Details" ;
        case TranslationKeys.TXT_CURRENT_PASSWORD : return "Current Password" ;
        case TranslationKeys.TXT_NEW_PASSWORD : return "New Password" ;
        case TranslationKeys.TXT_PASSWORD_UPDATE_FAILED : return "Password update failed" ;
        case TranslationKeys.TXT_PASSWORDS_DONT_MATCH : return "New passwords are not the same" ;
        case TranslationKeys.TXT_INVALID_PASSWORD : return "Invalid password" ;
        case TranslationKeys.TXT_CREATED : return "Created" ;
        case TranslationKeys.TXT_LAST_UPDATED : return "Last Updated" ;
        case TranslationKeys.TXT_FULL_NAME : return "Full Name" ;
        case TranslationKeys.TXT_ROLES : return "Roles" ;
        case TranslationKeys.TXT_SCANNED_TODAY : return "Scanned Today" ;
        case TranslationKeys.TXT_LABEL_CREATE : return "Create" ;
        case TranslationKeys.TXT_LABEL_UPDATE : return "Update" ;
        case TranslationKeys.TXT_LABEL_EDIT : return "Edit" ;
        case TranslationKeys.TXT_LABEL_CHANGE_PASSWORD : return "Change Password" ;
        case TranslationKeys.TXT_LINK_SIM_NUMBER : return "PCB SIM Serial Number" ;
        case TranslationKeys.TXT_LABEL_SCANNED_BY : return "Scanned By" ;
        case TranslationKeys.TXT_VIEW_SCAN_HISTORY : return "View Scan History" ;
        case TranslationKeys.TXT_LABEL_SEARCH : return "Search" ;
        case TranslationKeys.TXT_SIM_STATE : return "SIM Status" ;
        case TranslationKeys.TXT_SIM_ACTIVATION_TIME : return "SIM Activation Time" ;
        case TranslationKeys.TXT_SIM_LAST_STATUS_CHECK : return "SIM Last Status Check" ;
        case TranslationKeys.TXT_LABEL_DAILY_SCAN_COUNTS : return "Daily Scan Counts" ;
        case TranslationKeys.TXT_LABEL_SCANNED_ITEMS : return "Scanned Items" ;
        case TranslationKeys.TXT_LABEL_MISSING_SERIAL : return "Missing Serial Number" ;
        case TranslationKeys.TXT_LABEL_MISSING_SIM : return "Missing SIM Number" ;
        case TranslationKeys.TXT_LABEL_SERIAL_SIM_SAME : return "Serial and SIM are the same" ;
        default : return d ;
    }
}


const chineseText = (k,d) => {
    switch (k) {
        case TranslationKeys.TXT_LOGIN :
            return "登录";
        case TranslationKeys.TXT_LOGOUT :
            return "登出";
        case TranslationKeys.TXT_MANAGE_USERS :
            return "管理用户";
        case TranslationKeys.TXT_LINK_SERIAL_NUMBERS :
            return "升降机序列号";
        case TranslationKeys.TXT_SQUIRREL_MANUFACTURING_PORTAL :
            return "松鼠制造门户";
        case TranslationKeys.TXT_USERNAME :
            return "用户名";
        case TranslationKeys.TXT_PASSWORD :
            return "密码";
        case TranslationKeys.TXT_CHINESE :
            return "中文";
        case TranslationKeys.TXT_ENGLISH :
            return "English";
        case TranslationKeys.TXT_SCAN_NOTE :
            return "要接受，请按回车键或单击下面的代码。或者扫描屏幕上的代码";
        case TranslationKeys.TXT_LINK_HEADING :
            return "将序列号链接到SIM卡";
        case TranslationKeys.TXT_HOIST_SERIAL_LABEL :
            return "升降机序列号";
        case TranslationKeys.TXT_SIM_SERIAL_LABEL :
            return "PCB SIM序列号";
        case TranslationKeys.TXT_SCAN_COUNT_LABEL :
            return "扫描次数";
        case TranslationKeys.TXT_RESET_LABEL :
            return "重置";
        case TranslationKeys.TXT_LINK_ALREADY_EXISTS :
            return "链接已经存在";
        case TranslationKeys.TXT_LINK_CREATED :
            return "链接已经创建";
        case TranslationKeys.TXT_LINK_ERROR :
            return "链接错误";
        case TranslationKeys.TXT_LINK_ALREADY_EXISTS_SERIAL :
            return "序列号已经链接";
        case TranslationKeys.TXT_LINK_ALREADY_EXISTS_SIM :
            return "SIM卡号已经链接";
        case TranslationKeys.TXT_SAVE_LABEL :
            return "保存";
        case TranslationKeys.TXT_REFRESH_LABEL :
            return "刷新";
        case TranslationKeys.TXT_CANCEL_LABEL :
            return "取消";
        case TranslationKeys.TXT_LOGIN_NAME :
            return "登录名";
        case TranslationKeys.TXT_FRIENDLY_NAME :
            return "全名";
        case TranslationKeys.TXT_PASSWORD_CONFIRM :
            return "确认密码";
        case TranslationKeys.TXT_NEW_USER :
            return "新用户";
        case TranslationKeys.TXT_ACTIVE_USER :
            return "活跃";
        case TranslationKeys.TXT_DELETED_USER :
            return "删除";
        case TranslationKeys.TXT_LAST_LOGIN :
            return "上次登录";
        case TranslationKeys.TXT_SHOW_PASSWORD :
            return "显示密码";
        case TranslationKeys.TXT_ADMIN_USER :
            return "管理员用户";
        case TranslationKeys.TXT_CHANGE_PASSWORD :
            return "更改我的密码";
        case TranslationKeys.TXT_UPDATE_MY_DETAILS :
            return "更新我的详细信息";
        case TranslationKeys.TXT_CURRENT_PASSWORD :
            return "当前密码";
        case TranslationKeys.TXT_NEW_PASSWORD :
            return "新密码";
        case TranslationKeys.TXT_PASSWORD_UPDATE_FAILED :
            return "密码更新失败";
        case TranslationKeys.TXT_PASSWORDS_DONT_MATCH :
            return "新密码不一样";
        case TranslationKeys.TXT_INVALID_PASSWORD :
            return "无效密码";
        case TranslationKeys.TXT_CREATED :
            return "创建";
        case TranslationKeys.TXT_LAST_UPDATED :
            return "上次更新";
        case TranslationKeys.TXT_FULL_NAME :
            return "全名";
        case TranslationKeys.TXT_ROLES :
            return "角色";
        case TranslationKeys.TXT_SCANNED_TODAY :
            return "今天扫描";
        case TranslationKeys.TXT_LABEL_CREATE :
            return "创建";
        case TranslationKeys.TXT_LABEL_UPDATE :
            return "更新";
        case TranslationKeys.TXT_LABEL_EDIT :
            return "编辑";
        case TranslationKeys.TXT_LABEL_CHANGE_PASSWORD :
            return "更改密码";
        case TranslationKeys.TXT_LINK_SIM_NUMBER :
            return "PCB SIM序列号";
        case TranslationKeys.TXT_LABEL_SCANNED_BY :
            return "扫描者";
        case TranslationKeys.TXT_VIEW_SCAN_HISTORY :
            return "查看扫描历史";
        case TranslationKeys.TXT_LABEL_SEARCH :
            return "搜索";
        case TranslationKeys.TXT_SIM_STATE :
            return "SIM状态";
        case TranslationKeys.TXT_SIM_ACTIVATION_TIME :
            return "SIM激活时间";
        case TranslationKeys.TXT_SIM_LAST_STATUS_CHECK :
            return "SIM最后状态检查";
        case TranslationKeys.TXT_LABEL_DAILY_SCAN_COUNTS :
            return "每日扫描次数";
        case TranslationKeys.TXT_LABEL_SCANNED_ITEMS :
            return "扫描项目";
        case TranslationKeys.TXT_LABEL_MISSING_SERIAL :
            return "缺少序列号";
        case TranslationKeys.TXT_LABEL_MISSING_SIM :
            return "缺少SIM卡号";
        case TranslationKeys.TXT_LABEL_SERIAL_SIM_SAME :
            return "序列号和SIM卡号相同";
        default :
            return d;
    }
}



export const Translations = {
    text : (key, dvalue) => {
        let lang = window.localStorage.getItem("language") || "en" ;
        let activeTranslationSet = englishText ;
        if (lang === "zh") {
            activeTranslationSet = chineseText ;
        }

        return activeTranslationSet(key, dvalue) ;
    },

    setLanguage : (language) => {
        let lang = "en" ;
        if (language === "zh") {
            lang = "zh" ;
        }

        window.localStorage.setItem("language", lang) ;
        window.document.location.reload() ;
    },

    getLanguage : () => {
        return window.localStorage.getItem("language") || "en" ;
    }
}

